import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Route, useHistory } from 'react-router-dom';
import { Home } from "./pages/Home/Home";

const oktaAuth = new OktaAuth({
  issuer: 'https://newstore-sso.okta.com',
  clientId: '0oaq07kv5v9flLyWG357',
  redirectUri: window.location.origin + '/login/callback',
});

function App() {
  const history = useHistory()

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <SecureRoute path='/' exact={true} component={Home} />
      <Route path='/login/callback' component={LoginCallback} />
    </Security>
  )
}

export default App;
