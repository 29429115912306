import {useEffect, useState} from "react"
import {useQuery} from "@tanstack/react-query"
import Select from 'react-select'
import {useOktaAuth} from "@okta/okta-react"
import styles from "./Home.module.scss"
import logo from "../../assets/newstore-logo.png"
import {CopyToClipboard} from "../../components/CopyToClipboard/CopyToClipboard";

const {
  REACT_APP_TENANTS_URL,
  REACT_APP_SEARCH_OPTIONS_URL
} = process.env

const getTenants = async (stage) => {
  let token
  try {
    const tokenStorage = JSON.parse(localStorage.getItem('okta-token-storage'))
    token = tokenStorage.accessToken.accessToken
  }
  catch (err) {
    return err
  }
  const res = await fetch(`${REACT_APP_TENANTS_URL}?stage=${stage}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
  return res.json()
}

const getSearchOptions = async () => {
  const res = await fetch(REACT_APP_SEARCH_OPTIONS_URL)
  return res.json()
}

const STAGES = [
  {value: 'production', label: 'Production'},
  {value: 'staging', label: 'Staging'},
  {value: 'sandbox', label: 'Sandbox'},
]

export const Home = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [data, setData] = useState({})
  const [tenantsWithoutKey, setTenantsWithoutKey] = useState({})
  const [property, setProperty] = useState({})
  const [stage, setStage] = useState('production')
  const [tenantFilter, setTenantFilter] = useState('')
  const [options, setOptions] = useState([])

  const {data: tenants, isSuccess, isLoading} = useQuery(
    ['tenants', stage],
    () => getTenants(stage),
    {
      cacheTime: 60 * 60 * 1000,
      staleTime: 60 * 60 * 1000,
    }
  )
  const {data: searchOptions, isSuccess: haveSearchOptionSucceeded} = useQuery(
    ['searchOptions'],
    () => getSearchOptions(),
    {
      cacheTime: 60 * 60 * 1000,
      staleTime: 60 * 60 * 1000,
    }
  )

  useEffect(() => {
    if (isSuccess && Object.keys(property).length) {
      let tempData = {}
      let tempTenantsWithoutKey = []
      Object.keys(tenants).forEach(tenant => {
        const filteredKey = Object.keys(tenants[tenant]).find(key => key === property.value)
        if (filteredKey) {
          tempData = {
            ...tempData,
            [tenant]: {
              ...tempData[tenant],
              [filteredKey]: tenants[tenant][filteredKey]
            }
          }
        } else {
          tempTenantsWithoutKey.push(tenant)
        }
      })
      setData(tempData)
      setTenantsWithoutKey(tempTenantsWithoutKey)
    } else {
      setData({})
      setTenantsWithoutKey([])
    }
  }, [property, isSuccess, tenants])

  useEffect(() => {
    if (haveSearchOptionSucceeded) {
      setOptions(searchOptions.map(option => ({
        value: option,
        label: option.replaceAll('.', ' > '),
      })))
    }
  }, [haveSearchOptionSucceeded, searchOptions])

  if(!authState) {
    return <div>Loading...</div>;
  }

  if(!authState.isAuthenticated) {
    void oktaAuth.signInWithRedirect()
  }

  const stageChanged = selectedOption => {
    setProperty({})
    setTenantFilter('')
    setStage(selectedOption.value)
  }

  return (
    <div className={styles.container}>
      <main className={styles.main}>
        <div className={styles.header}>
          <h2>Associate App - Configuration Checker</h2>
          <img src={logo} width={170} height={31} alt="NewStore Logo" />
        </div>
        <div className={styles.controls}>
          <label>
            Property:
            <Select
              options={options}
              className={styles.reactSelect}
              placeholder=""
              onChange={selectedOption => setProperty(selectedOption ?? {})}
              isDisabled={isLoading}
              isClearable
            />
          </label>
          <label>
            Retailer:
            <input type='search' className={styles.mainInput} value={tenantFilter}
                   onChange={e => setTenantFilter(e.target.value)} disabled={isLoading}/>
          </label>
          <label>
            Stage:
            <Select
              options={STAGES}
              className={styles.reactSelect}
              defaultValue={{value: 'production', label: 'Production'}}
              onChange={stageChanged}
              isDisabled={isLoading}
            />
          </label>
        </div>
        <div className={styles.content}>
          {Object.keys(data).length
            ? Object.keys(data)
              .filter(tenant => tenantFilter ? tenant.includes(tenantFilter) : true)
              .map(tenant => {
                const value = typeof data[tenant][property.value] === 'object'
                  ? JSON.stringify(data[tenant][property.value], null, 2)
                  : `${String(data[tenant][property.value])}`
                return (
                  <div key={tenant} className={styles.card}>
                    <div className={styles.cardHeading}>
                      <h2 className={styles.title}>
                        <a href={`https://${tenant}.p.newstore.net/api/v1/associate-app/config`} target='_blank'
                           rel="noreferrer">
                          {tenant}
                        </a>
                      </h2>
                      <CopyToClipboard content={value}/>
                    </div>
                    <pre>{value}</pre>
                  </div>
                )
              })
            : isLoading
              ? 'Processing tenant config... Please wait.'
              : Object.keys(property).length
                ? 'There are no tenants with the selected property set'
                : ''
          }
        </div>
        {
          !!tenantsWithoutKey.length && (
            <>
              <h2>Tenants not having &quot;<span style={{color: '#e41a4f'}}>{property?.label}</span>&quot; property set</h2>
              <div className={styles.content}>
                {
                  tenantsWithoutKey
                    .filter(tenant => tenantFilter ? tenant.includes(tenantFilter) : true)
                    .map(entry => (
                      <div key={entry} className={styles.card}>
                        <h2 className={styles.title}>
                          <a href={`https://${entry}.p.newstore.net/api/v1/associate-app/config`} target='_blank'
                             rel="noreferrer">
                            {
                              typeof entry === 'object'
                                ? JSON.stringify(entry, '', 2)
                                : entry
                            }
                          </a>
                        </h2>
                      </div>
                    ))
                }
              </div>
            </>
          )
        }
      </main>
    </div>
  )
}
